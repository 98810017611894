import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Article } from "../lib/types";
import LinkText from "./link";

export interface ArticleCardProps {
  article: Article;
  categorySlug: string;
  className?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  categorySlug,
  className = "",
}) => {
  // Limit summary at 215 characters
  let summary = article.summary.substring(0, 215);

  if (article.summary.length > 215) {
    summary = `${summary}...`;
  }

  const linkWithSlash = `${categorySlug}/${
    article.SEO.slug.endsWith("/") ? article.SEO.slug : article.SEO.slug + "/"
  }`;

  return (
    <div
      className={`${className} shadow-center-dark rounded-md
    duration-300 ease-in-out 
           group
            hover:scale-105 
            hover:shadow-large-center 
            active:scale-105
            active:shadow-large-center
    `}
    >
      <Link className="" to={linkWithSlash}>
        {article.cover &&
          (article.cover?.localFile.childImageSharp ? (
            <GatsbyImage
              image={getImage(article.cover.localFile)}
              alt={`Image de Couverture article ${article.title}`}
              objectFit={"cover"}
              objectPosition={"center"}
              className={"rounded-t-md w-full object-cover object-center h-60"}
            />
          ) : (
            <img
              src={article.cover.localFile.publicURL}
              alt={`Image de Couverture article ${article.title}`}
              className={"rounded-t-md w-full object-cover object-center h-60"}
            />
          ))}
      </Link>
      <div className={"p-5 flex flex-col space-y-4"}>
        <h3 className="text-lg">
          <LinkText active label={article.title} href={linkWithSlash} />
        </h3>
        <p className={"text-sm lg:text-base"}>{summary}</p>
        <Link to={linkWithSlash} className={"text-secondary-100 underline"}>
          Lire la suite
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
