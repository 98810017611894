import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Article, Category } from "./types";
import { debounce } from "./functions";

/**
 * react custom hook used for paginating data.
 * @example
 *    const { currentPage, next, currentData } = usePagination(array, itemsPerPage);
 *
 * @param data the data to paginate
 * @param perPage the number of items to show per page
 * @param wrap set this to true in order to returns to page 1 when the "next" method is called
 * @param defaultPage the default page to start with
 * */
export function usePagination<T>({
  data,
  perPage,
  wrap = false,
  defaultPage = 1,
}: {
  data: T[];
  perPage: number;
  wrap?: boolean;
  defaultPage?: number;
}) {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const maxPage = Math.ceil(data.length / perPage);

  function currentData() {
    const begin = (currentPage - 1) * perPage;
    const end = begin + perPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => {
      if (wrap && currentPage === maxPage) {
        return 1;
      } else {
        return Math.min(currentPage + 1, maxPage);
      }
    });
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

/**
 * hook used to test for media queries, in order to execute action according
 * to the with of the window.
 * @example
 *
 *    const isTablet = useMediaQuery("(max-width: 1200px)");
 *
 * */
export function useMediaQuery(query: string = "(min-width: 576px)") {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

/**
 * hook used for ordering articles by "published_date" in descending order
 *  (i.e: the most recent will be showed first)
 * The result is memoized in order to not run it everytime the component is mounted
 * @param articles
 */
export function useArticleOrdered(articles: Article[]) {
  return useMemo(() => {
    return articles.sort(
      // @ts-ignore
      (a, b) => new Date(b.published_date) - new Date(a.published_date)
    );
  }, [articles]);
}

/**
 * Hook used for changing the selected category on the sidebar depending on the
 * scroll position
 * @example :
 *
 *      const selectedCategory = useSideBarScroll(categoriesToShow);
 *
 */
export function useSideBarScroll(categoriesToShow: Category[]) {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  let selectedCategory = categoriesToShow[currentCategoryIndex]?.SEO.slug;

  /**
   * Check the scroll to change the current selected category on the sidebar
   * when the viewport is at the position where the category is
   */
  const checkScroll = () => {
    setCurrentCategoryIndex(prevIndex => {
      if (categoriesToShow.length > 1) {
        // when scrolling down
        let nextCategorySlug = categoriesToShow[prevIndex + 1]?.SEO.slug;
        let sectionNextCategory = document.getElementById(nextCategorySlug);

        if (sectionNextCategory) {
          let topOfSection = sectionNextCategory.offsetTop;
          if (window.scrollY >= topOfSection - 150) {
            return prevIndex + 1 >= categoriesToShow.length
              ? categoriesToShow.length - 1
              : prevIndex + 1;
          }
        }

        // when scrolling up
        let prevCategorySlug = categoriesToShow[prevIndex - 1]?.SEO.slug;
        let sectionPrevCategory = document.getElementById(prevCategorySlug);
        if (sectionPrevCategory) {
          let bottomOfSection =
            sectionPrevCategory.offsetTop + sectionPrevCategory.offsetHeight;
          if (window.scrollY <= bottomOfSection - 150) {
            return prevIndex - 1 <= 0 ? 0 : prevIndex - 1;
          }
        }
      }
      return prevIndex;
    });
  };

  useEffect(() => {
    checkScroll();

    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return selectedCategory;
}

/**
 * Hook utilisé pour créer un délai sur une fonction
 *
 * @example
 *    const debounced = useDebouncedCallBack<() => void>(500)
 *
 *    debounced(() => { // ... })
 *
 * @param timeout
 */
export function useDebouncedCallBack<T extends Function>(
  timeout: number = 500
): (callback: T) => void {
  return React.useCallback(
    debounce((callback: T) => {
      callback();
    }, timeout),
    []
  );
}

/**
 * Hook utilisé pour lancer un useEffect mais pas au premier rendu
 *
 * @param effect
 * @param deps
 */
export function useEffectNotFirst<TEffectFn extends Function>(
  effect: TEffectFn,
  deps: any[]
) {
  const isNotFirstRender = useRef(false);

  useEffect(() => {
    if (!isNotFirstRender.current) {
      isNotFirstRender.current = true;
      return;
    }

    effect();
  }, deps);
}
