import { Link } from "gatsby";
import * as React from "react";
import { Category } from "../lib/types";
import Icon from "./icon";
import { linkWithSlash } from "../lib/functions";

export interface CategorySideBarProps {
  basePath: string;
  title: string;
  categoryTitle: string;
  className?: string;
  categories: Array<Pick<Category, "name" | "SEO">>;
  activeSlug?: string;
  open?: boolean;
  onToggle: () => void;
}

const CategorySideBar: React.FC<CategorySideBarProps> = ({
  basePath,
  categories,
  title,
  categoryTitle,
  activeSlug,
  className = "",
  open = false,
  onToggle,
}) => (
  <>
    <aside
      className={`${className} bg-light-50 p-3  ${
        !open && "shadow-md"
      } md:shadow-none`}
    >
      {/* Placeholder when closed */}
      <div className={`md:hidden flex justify-between items-center`}>
        <div className={"content-center"}>{!open && title}</div>
        <button
          className={"p-2"}
          aria-label={
            !open
              ? "Voir toutes les catégories"
              : "Fermer le menu des catégories"
          }
          onClick={() => onToggle && onToggle()}
        >
          <Icon icon={!open ? "menu" : "close"} className={"h-5"} />
        </button>
      </div>

      <nav
        className={`${!open && "hidden"} mt-4 space-y-4 px-5 md:py-0 md:block`}
      >
        <span className="font-bold">{categoryTitle}</span>

        <ul className={"flex flex-col space-y-4"}>
          <ul
            className={`flex flex-col pl-4 max-h-[45vh] overflow-y-scroll
             md:max-h-[55vh] 
             lg:max-h-[60vh] 
             xl:max-h-[70vh]`}
          >
            {categories.map((category, index) => (
              <li key={index}>
                <Link
                  onClick={onToggle}
                  to={`${linkWithSlash(basePath)}#${category.SEO.slug}`}
                  className={`text-sm mt-0 grid grid-cols-10 hover:text-secondary-100 ${
                    activeSlug === category.SEO.slug
                      ? "text-dark-100"
                      : "text-dark-50"
                  }`}
                >
                  <span
                    className={`col-span-1 border-l-2 ${
                      activeSlug === category.SEO.slug
                        ? "border-secondary-100"
                        : "border-gray-400/30"
                    }`}
                  />
                  <span className={"col-span-9 py-1"}>{category.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </ul>
      </nav>
    </aside>
  </>
);

export default CategorySideBar;
